<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3" text v-on="on" :class="btnClass">Add Tiebreak Match</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Add Match</v-toolbar-title>
      </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <match-settings
                v-if="settings"
                :setting="settings"
                :edit="true"
                :notime="true"
              ></match-settings>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-autocomplete
                :items="availableHomeTeams"
                return-object
                v-model="home"
                item-text="name"
                label="Team 1"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.rank">Curently {{item.rank | ordinal}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="title text-center">
              VS
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :items="availableAwayTeams"
                return-object
                v-model="away"
                item-text="name"
                label="Team 2"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle v-if="item.rank">Currently {{item.rank | ordinal}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Court"
                v-model="court"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                :items="availableRefTeams"
                return-object
                v-model="ref"
                item-text="name"
                label="Reffing Team"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="text-center" cols="12" >
              <v-btn
                color="color3 color3Text--text"
                class="mr-2"
                :disabled="!complete"
                :loading="loading"
                @click.stop="save"
              >Add</v-btn>
              <v-btn @click.stop="dialog = false">Cancel</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Match from '@/classes/Match'
import MatchOptions from '@/classes/MatchOptions'
import max from 'lodash.max'
import MatchSettings from '@/components/Tournament/RoundSettings/MatchSettings'
import MatchSettingsJs from '@/classes/MatchSettings'

export default {
  props: ['pool', 'btnClass'],
  data () {
    return {
      dialog: false,
      home: null,
      away: null,
      ref: null,
      court: null,
      startTime: null,
      loading: false,
      matchType: null,
      settings: null
    }
  },
  computed: {
    complete () {
      return this.home && this.away && this.settings
    },
    availableHomeTeams () {
      const taken = new Set()
      if (this.away) taken.add(this.away.id)
      if (this.ref) taken.add(this.ref.id)
      return this.pool.teams.filter(team => {
        return !taken.has(team.id)
      })
    },
    availableAwayTeams () {
      const taken = new Set()
      if (this.home) taken.add(this.home.id)
      if (this.ref) taken.add(this.ref.id)
      return this.pool.teams.filter(team => {
        return !taken.has(team.id)
      })
    },
    availableRefTeams () {
      const taken = new Set()
      if (this.home) taken.add(this.home.id)
      if (this.away) taken.add(this.away.id)
      return this.pool.teams.filter(team => {
        return !taken.has(team.id)
      })
    },
    matchOptions () {
      return MatchOptions
    },
    showGames () {
      return this.matchType && (this.matchType.id === 1 || this.matchType.id === 4)
    },
    dto () {
      return {
        poolId: this.pool.id,
        number: max(this.pool.matches.map(m => m.number)) + 1,
        court: this.court || null,
        homeTeam: this.home ? this.home.id : null,
        awayTeam: this.away ? this.away.id : null,
        refTeam: this.ref ? this.ref.id : null,
        startTime: this.startTime,
        isMatch: this.settings.isMatch,
        // games: this.settings.dto.gameSettings,
        settings: this.settings.dto,
        manualRefId: null,
        id: 0,
        bracketId: null
      }
    },
    dtoOG () {
      const match = new Match(this.$VBL, {
        poolId: this.pool.id,
        number: max(this.pool.matches.map(m => m.number)) + 1,
        court: this.court,
        homeTeam: this.home,
        awayTeam: this.away,
        refTeam: this.ref,
        startTime: this.startTime,
        settings: JSON.parse(JSON.stringify(this.settings.dto)),
        games: JSON.parse(JSON.stringify(this.settings.gameSettings))
      }, 'pool')

      return match.dto
    },
    dtoOG2 () {
      const match = new Match(this.$VBL, {
        poolId: this.pool.id,
        number: max(this.pool.matches.map(m => m.number)) + 1,
        court: this.court,
        homeTeam: this.home,
        awayTeam: this.away,
        refTeam: this.ref,
        startTime: this.startTime,
        settings: JSON.parse(JSON.stringify(this.settings.dto)),
        games: JSON.parse(JSON.stringify(this.settings.gameSettings))
      }, 'pool')

      return match.fullDto
    }
  },
  methods: {
    save () {
      this.loading = true
      console.log(this.dto)
      this.$VBL.post.matches([this.dto])
        .then(r => {
          this.dialog = false
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.home = null
        this.away = null
        this.ref = null
        this.startTime = null
        this.court = this.pool.courts ? this.pool.courts.split(',')[0] : null
        this.settings = new MatchSettingsJs(JSON.parse(JSON.stringify(this.pool.settings[0])))
      } else {
        this.loading = false
      }
    }
  },
  components: {
    MatchSettings
  }
}
</script>

<style>

</style>
